var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        {
          attrs: { row: "", wrap: "", "justify-center": "", "align-center": "" }
        },
        _vm._l(_vm.groupedJournals, function(journals, month, index) {
          return _c(
            "v-flex",
            { key: index + "-" + month, attrs: { xs12: "" } },
            [
              _c("h3", { staticClass: "starling-h5 mt-2 mb-1" }, [
                _vm._v(" " + _vm._s(month))
              ]),
              _vm._l(journals, function(journal, month, index) {
                return _c(
                  "v-flex",
                  {
                    key: index + "-" + month + "-" + journal.id,
                    attrs: { xs12: "", "mt-2": "" }
                  },
                  [
                    _c("journal-card", {
                      key: journal.id + "-" + index,
                      attrs: { journal: journal },
                      on: { delete: _vm.onDelete, update: _vm.onUpdate }
                    })
                  ],
                  1
                )
              })
            ],
            2
          )
        }),
        1
      ),
      _c("div", { ref: "sentinel" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }