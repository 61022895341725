var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "journals-card",
      attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
    },
    [
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c("h2", { staticClass: "starling-h2 StarlingPrimary1--text" }, [
          _vm._v(_vm._s(_vm.$t("app.tools.journal.page_title")))
        ]),
        _c("h4", { staticClass: "starling-h4 StarlingPrimary1--text mb-2" }, [
          _vm._v(_vm._s(_vm.$t("app.tools.journal.page_sub_title")))
        ]),
        _c("p", { staticClass: "starling-text" }, [
          _vm._v(_vm._s(_vm.$t("app.tools.journal.page_instruction")))
        ])
      ]),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-card",
            {
              staticClass:
                "create-journal-card d-flex align-center justify-center",
              attrs: { flat: "" }
            },
            [
              _c(
                "v-layout",
                {
                  attrs: { row: "", "justify-center": "", "align-center": "" }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "primary-button",
                        {
                          attrs: { depressed: "" },
                          on: { click: _vm.onCreate }
                        },
                        [
                          _c("label", { staticClass: "mx-3" }, [
                            _vm._v(
                              _vm._s(_vm.$t("app.tools.journal.actions.create"))
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.totalJournals && !_vm.loading
        ? [
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _c(
                  "h3",
                  { staticClass: "starling-h3 StarlingPrimary1--text" },
                  [_vm._v(_vm._s(_vm.$t("app.tools.journal.sections.title")))]
                ),
                _c(
                  "v-flex",
                  { attrs: { xs4: "", "text-right": "" } },
                  [
                    _c("sort-menu", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.totalJournals,
                          expression: "totalJournals"
                        }
                      ],
                      attrs: {
                        value: _vm.sortSelected,
                        sortList: _vm.sortList,
                        actionClick: _vm.onSortJournals
                      }
                    })
                  ],
                  1
                ),
                _c("v-spacer"),
                _c("journal-list", {
                  attrs: { sortSelected: _vm.sortSelected },
                  on: { delete: _vm.onDelete, update: _vm.onUpdate }
                })
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }