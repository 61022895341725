


















/* eslint-disable */
import Vue from 'vue';
import { IJournal, IJournalPage } from '@/scripts/store/modules/tools/journals/types';
import SortMenu from '@/views/components/utilities/sort-menu.vue';
import JournalCard from '@/views/tools/journal/components/journal-card.vue';

export default Vue.extend({
  name: 'journal-list',
  components: {
    SortMenu,
    JournalCard,
  },
  props: {
    journals: {
      type: Array as () => Array<IJournal>,
      required: false,
    },
    sortSelected: {
      type: String, 
      required: false,
    }
  },
  data() {
    return {
      loading: false,
      sortBy: null as string | null,
      sortDir: null as string | null,
      activePage: 1,
      observer: null as IntersectionObserver | null,
    };
  },
  computed: {
    groupedJournals(): Record<string, IJournal[]> {
      const grouped: Record<string, IJournal[]> = {};
      this.storeJournals.forEach(journal => {
        const month = this.formatMonth(journal.journalDate);
        if (!grouped[month]) {
          grouped[month] = [];
        }
        grouped[month].push(journal);
      });
      return grouped;
    },
    storeJournals(): IJournal[] {
      return this.$store.getters['journals/journals'];
    },
    totalJournals(): number {
      return this.$store.getters['journals/totalJournals'];
    },
    paginateLength(): number {
      if (!this.totalJournals) return 1;
      return Math.floor((this.totalJournals) / 5) + ((this.totalJournals % 5) && 1);
    },
    sortList(): string[] {
      return (this.$t('app.tools.journal.sorts') as unknown) as string[];
    },
  },
  mounted(){
    this.$nextTick(() => {
      const sentinelElement = this.$refs.sentinel as Element;
      if (sentinelElement) {
        this.observer = new IntersectionObserver((entries) => {
          const sentinel = entries[0];
          if (sentinel.isIntersecting) {
            this.loadMoreJournals();
          }
        });
        this.observer.observe(sentinelElement);
      }
    });
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    loadMoreJournals() {
      if (this.activePage < this.paginateLength && !this.loading) {
        this.activePage += 1;
        this.loadJournals(this.activePage);
      }
    },
    loadJournals(page: number) {
      this.loading = true;
      const config = {
        page: page > 0 ? page - 1 : 0,
        sort: this.sortBy || 'date',
        dir: this.sortSelected === this.sortList[0] ? 'DESC' : 'ASC'
      };
      this.$store.dispatch('journals/getJournals',config).then((res: IJournalPage) => {
        this.loading = false;
      });
    },
    onDelete(journal: IJournal) {
      this.$emit('delete', journal);
    },
    onUpdate(journal: IJournal) {
    },
    formatMonth(jDate: Date) {
      const date = new Date(jDate);
      const curLanguage = this.$store.getters.currentLanguage;
      return date.toLocaleString(curLanguage, { month: 'long', year: 'numeric' });
    },
  },
});
