





































import Vue from 'vue';
import { IJournal, IJournalPage } from '@/scripts/store/modules/tools/journals/types';
import JournalList from '@/views/tools/journal/components/journal-list.vue';
import primaryButton from '@/views/components/button/primary-button.vue';
import SortMenu from '@/views/components/utilities/sort-menu.vue';

export default Vue.extend({
  name: 'journalsView',
  components: {
    JournalList,
    primaryButton,
    SortMenu,
  },
  data() {
    return {
      creating: false,
      loading: false,
      form: {},
      createMode: false,
      sortSelected: undefined as string | undefined,
    };
  },
  computed: {
    storeJournals(): IJournal[] {
      return this.$store.getters['journals/journals'];
    },
    totalJournals(): number {
      return this.$store.getters['journals/totalJournals'];
    },
    sortList(): string[] {
      return (this.$t('app.tools.journal.sorts') as unknown) as string[];
    },
  },
  mounted() {
    this.sortSelected = this.sortList[0];
    this.$store.dispatch('journals/resetJournals');
    this.loadJournals(1);
  },
  methods: {
    loadJournals(page: number) {
      this.loading = true;
      const config = {
        page: 0,
        sort: 'date',
        dir: this.sortSelected === this.sortList[0] ? 'DESC' : 'ASC',
      };
      this.$store.dispatch('journals/getJournals', config).then((res: IJournalPage) => {
        this.loading = false;
      });
    },
    onSortJournals(sortDir: string) {
      this.sortSelected = sortDir;
      this.$store.dispatch('journals/resetJournals');
      this.loadJournals(1);
    },
    onCreate() {
      this.$router.push({ name: 'journal-create' });
    },
    onDelete(journal: IJournal) {
      // this.$router.push({ name: 'journal-edit', params: { id: journal.id as string } });
    },
    onUpdate() {
      this.$store.dispatch('journals/resetJournals');
      this.loadJournals(1);
    },
  },
});
